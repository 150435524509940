export const COLOR_BRAND_PRIMARY = "#FED202";
export const COLOR_WHITE = "#ffffff";
export const COLOR_BLACK = "#000000";
export const COLOR_DARK_BLUE = "#314757";
export const COLOR_SUCCESS = "#3CFFBE";
export const COLOR_DANGER = "#E58E90";

export const COLOR_GREY = "#858585";
export const COLOR_LIGHT_GREY = "#f3f3f3";
export const COLOR_RED = "#ed2939";
export const COLOR_DARK_GREEN = "#1b655e";
export const COLOR_GOLDEN_BROWN = "#918913";
export const COLOR_YELLOW = "#f0dc25";
export const COLOR_BLUE_GREY = "#a8b8c4";


export const COLOR_PASTEL_RED = "#FF9595";
export const COLOR_PASTEL_ORANGE = "#FFC395";
export const COLOR_PASTEL_YELLOW = "#FFE795";
export const COLOR_PASTEL_GREEN = "#CFFF95";
export const COLOR_PASTEL_TURQUOISE = "#95FFC2";
export const COLOR_PASTEL_BLUE = "#95FCFF";
export const COLOR_PASTEL_VIOLET = "#95B9FF";
export const COLOR_PASTEL_PURPLE = "#CFA0FF";
export const COLOR_PASTEL_PINK = "#FFA6F9";

export const CHART_PALETTE = [
  COLOR_PASTEL_RED,
  COLOR_PASTEL_ORANGE,
  COLOR_PASTEL_YELLOW,
  COLOR_PASTEL_GREEN,
  COLOR_PASTEL_TURQUOISE,
  COLOR_PASTEL_BLUE,
  COLOR_PASTEL_VIOLET,
  COLOR_PASTEL_PURPLE,
  COLOR_PASTEL_PINK
];
