import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import createClient from "./apolloClient";

import Container from '@material-ui/core/Container';

import Polls from "./Polls";

import "./App.css";

const client = createClient();

export default function App() {
  return (
    <ApolloProvider client={client}>
      <main>
        <Container>
          <Polls />
        </Container>
      </main>
    </ApolloProvider>
  );
}