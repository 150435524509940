/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PollType {
  MoodRating = "MoodRating",
  SingleChoice = "SingleChoice",
  StarRating = "StarRating",
  YesNoChoice = "YesNoChoice",
}

//==============================================================
// END Enums and Input Objects
//==============================================================