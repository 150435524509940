import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import LazyLoad from "react-lazyload";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  BarChart,
  Bar,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import format from "date-fns/format";

import {
  Polls as PollsQueryData,
  Polls_pollHistory_result,
  Polls_pollHistory
} from "./generated/Polls";
import { PollType } from "./generated/globalTypes.d";
import {
  COLOR_PASTEL_TURQUOISE,
  CHART_PALETTE,
  COLOR_PASTEL_RED
} from "./colors";

const POLLS_QUERY = gql`
  query Polls {
    pollHistory(order: Desc) {
      id
      validFrom
      validTo
      question
      pollType
      choices
      result {
        answerCount
        abstentionCount
        histogram {
          label
          value
        }
        meanValue
      }
    }
  }
`;

export default function Polls(props: {}) {
  const { data, loading } = useQuery<PollsQueryData>(POLLS_QUERY);

  return (
    <>
      <div className="c-header">
        <img
          className="c-logo c-logo--hashtag"
          src="https://www.datenspender.de/images/werde-datenspender.svg"
          alt="#WerdeDatenspender"
        />
      </div>
      <Typography
        variant="h3"
        component="h1"
        style={{ color: "#ffffff", marginTop: "40px" }}
      >
        Auswertung der Ergebnisse
      </Typography>

      {loading && (
        <Card className="dd-pollcard">
          <CardContent>
            <Skeleton variant="rect" width={400} height={40} />
            <Skeleton
              variant="rect"
              width={200}
              height={25}
              style={{ marginTop: "10px" }}
            />
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" style={{ margin: "100px" }} />
            </div>
            <Skeleton variant="rect" width={300} height={57} />
          </CardContent>
        </Card>
      )}

      {data &&
        data.pollHistory.map(poll => (
          <LazyLoad key={poll.id} height={500}>
            <PollResultCard poll={poll} />
          </LazyLoad>
        ))}
    </>
  );
}

const PIE_COLORS = [COLOR_PASTEL_RED, COLOR_PASTEL_TURQUOISE];

const ResultChart: React.SFC<{
  result: Polls_pollHistory_result;
  pollType: PollType;
}> = ({ result, pollType }) => {
  if (pollType === PollType.YesNoChoice) {
    return (
      <ResponsiveContainer maxHeight={400} aspect={1} className="dd-chart">
        <PieChart>
          <Pie
            data={result.histogram}
            labelLine={false}
            fill="#8884d8"
            innerRadius="75%"
            outerRadius="100%"
            dataKey="value"
            nameKey="label"
          >
            {result.histogram.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={PIE_COLORS[index % PIE_COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => [
              toPercent(value as number, result.answerCount),
              name === "Yes" ? "Ja" : "Nein"
            ]}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  let data = result.histogram.map((entry, index) => ({
    ...entry,
    index,
    fill: ""
  }));

  data = data.sort((a, b) =>
    a.value === b.value ? 0 : a.value > b.value ? -1 : 1
  );
  data = data.map((entry, index) => ({
    ...entry,
    fill: CHART_PALETTE[index % CHART_PALETTE.length]
  }));
  data = data.sort((a, b) => a.index - b.index);

  return (
    <ResponsiveContainer aspect={1.8} width="80%" className="dd-chart">
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <XAxis dataKey="value" type="number" hide />
        <YAxis dataKey="label" type="category" width={180} />
        <Tooltip
          labelFormatter={() => null}
          formatter={(value, name) => [
            toPercent(value as number, result.answerCount),
            null
          ]}
        />
        <Bar dataKey="value" fill="#8884d8" maxBarSize={30}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const PollResultCard: React.SFC<{ poll: Polls_pollHistory }> = ({ poll }) => {
  return (
    <Card className="dd-pollcard">
      <CardContent>
        <Typography variant="h4" component="h3">
          {poll.question}
        </Typography>
        <Typography color="textSecondary" component="div">
          {poll.validFrom && format(new Date(poll.validFrom), "dd.MM.yy HH:mm")}
          {poll.validFrom && <span> - </span>}
          {poll.validTo ? (
            poll.validTo ? (
              format(new Date(poll.validTo), "dd.MM. HH:mm")
            ) : null
          ) : (
            <Chip size="small" label="offen" />
          )}
        </Typography>
        {poll.result && (
          <>
            <ResultChart result={poll.result} pollType={poll.pollType} />
            <div style={{ textAlign: "center" }}>
              <Typography variant="h3" component="span" className="dd-metric">
                {poll.result.answerCount}
                <Typography
                  variant="subtitle1"
                  component="span"
                  color="textSecondary"
                  className="dd-metric__label"
                >
                  {poll.result.answerCount > 1 ? "Antworten" : "Antwort"}
                </Typography>
              </Typography>
              <Typography variant="h3" component="span" className="dd-metric">
                {poll.result.abstentionCount}
                <Typography
                  variant="subtitle1"
                  component="span"
                  color="textSecondary"
                  className="dd-metric__label"
                >
                  {poll.result.abstentionCount > 1
                    ? "Enthaltungen"
                    : "Enthaltung"}
                </Typography>
              </Typography>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

function toPercent(value: number, total: number): String {
  return value === 0 ? "0%" : Math.round((value / total) * 100) + "%";
}
